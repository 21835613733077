import React, { useState } from 'react'
import ModalQuestion from './ModalQuestion';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';


const CachePreview = (props) => {

  //const isLocked = true;
  //console.log("field=" + props.gameData[props.cacheId + "-unlock"] + "isLockedLocal= " + isLockedLocal)
  //const [isLocked, setIsLocked] = useState(true)

  // if (props.gameData[props.cacheId + "-unlock"] !== "") {
  //   setIsLocked(false);
  // }

  const [modalQuestionShow, setModalQuestionShow] = useState(false)

  console.log("CachePreview")

  // constructor(props) {
  //   super(props)
  //   this.state = { 
  //                  modalQuestionShow: false,
  //                  isLocked: true,
  //                  unlockUrl: ""
  //                };
  // }

  // componentDidMount() {

  //   //Check to see if already unlocked
  //   console.log("hi");
  //   console.log(this.props.gameData[this.props.cacheId + "-unlock"]);
  //   if (this.props.gameData[this.props.cacheId + "-unlock"] !== "") {
  //     this.setState({isLocked: false});
  //   }

  //   //this.setState({unlockUrl: "cache" + this.props.location.search + "&act=unlock&cid=" + this.props.cacheData.cid})
  // }
  if (props.gameData) {
    const cacheUrl = "/cache?id=" + props.gameData.id + "&pc=" + props.gameData.pc + "&cid=" + props.cacheId

    return (
      <div className="App">
        <div className="flex flex-horizontal-center margin-t-20 h4">{ props.cacheData.name }</div>
        <div><img alt="tasty treat" className="cache-img" src={"/images/cache/" + props.cacheData.imgTreat} /></div>
        <div className="section-subtitle font-secondary text-muted text-center">{ props.cacheData.descShort }</div>

        {props.gameData[props.cacheId + "-unlock"] ? (
          <div className="flex flex-horizontal-center margin-t-20"><Link className="btn btn-custom" to={cacheUrl}>Start your hunt</Link></div>
          ) : (
          <div className="flex flex-horizontal-center margin-t-20"><button className="btn btn-custom" onClick={() => setModalQuestionShow(true)}>Unlock your hunt</button></div>
          )}

          <hr/>

          <ModalQuestion
            show={modalQuestionShow}
            onHide={() => setModalQuestionShow(false)}
            closeModal={() => setModalQuestionShow(false)}
            gameData={props.gameData}
            cacheId={props.cacheId}
            cacheData={props.cacheData}
            queryString={props.location.search}
          />

      </div>
    );
  } else {
    return (
      <div className="row margin-t-20">
        <div className="col">
          <div className="text-center"><img alt="tasty treat" className="cache-img" src={"/images/cache/" + props.cacheData.imgTreat} /></div>
          <div className="section-subtitle font-secondary text-muted text-center">{ props.cacheData.descShort }</div>
        </div>
      </div>
    );
  }
}
export default withRouter(CachePreview);
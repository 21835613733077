import React, { useEffect, useState } from 'react'
import Navbar from './components/Navbar'
import Pricing from './components/Pricing'
import Process from './components/Process'
import Testi from './components/Testi'
import Started from './components/Started'
import SocialMedia from './components/SocialMedia'
import ModalBuy from './components/ModalBuy'
import CachePreview from './components/CachePreview'
import { HashLink as Link } from 'react-router-hash-link'
import { Carousel } from 'react-responsive-carousel'
import detectBrowserLanguage from 'detect-browser-language'


const Home = (props) => {

    const [modalBuyShow, setModalBuyShow] = useState(false)
    const [showNav, setShowNav] = useState(false)

    //Multilanguage
    var locale
    if (detectBrowserLanguage().substring(0, 2) === 'es') {
        console.log("SPANISH DETECTED");
      locale = 'ES'
    } else {
      locale = 'EN'
    }

    const languagesCacheData = {
      EN: require('./locale/cacheData-en.json'),
      ES: require('./locale/cacheData-es.json')
    };
    
    const cacheData = languagesCacheData[locale];

    const languagesStringData = {
      EN: require('./locale/strings-en.json'),
      ES: require('./locale/strings-es.json')
    };
    
   const strings = languagesStringData[locale];

    //Scroll handling for navbar

   const handleScroll = () => {

     if (window.pageYOffset > 20) {
         setShowNav(true)

      } else {
         setShowNav(false)
      }
    };

    const queryString = require('query-string')
    const param = queryString.parse(props.location.search)
    
    useEffect(() => {
        if (window) {
            if (param['checkout'] === 'true')
                setModalBuyShow(true)
            
            document.addEventListener("scroll", handleScroll);
        }
    }, [])

	return (
        <React.Fragment>
            <Navbar 
                strings={strings}
                show={showNav}
                setModalBuyShow={setModalBuyShow} 
            />

            <div className="overflow">
            
                {(strings['alert'] !== "") ? 
                <div class="alert alert-warning" role="alert">
                        {strings['alert']}
                </div>
                : null}

                <section className="section bg-home home-half" id="home">
                    <div className="bg-overlay"></div>
                    <div className="display-table">
                        <div className="display-table-cell">
                            <div className="container">
                                <div className="row vertical-content">
                                    <div className="col-lg-7 text-white text-left margin-t-30">
                                        <h1 className="home-title">El Dulce Camino</h1>
                                        <p className="padding-t-15 home-desc home-subtitle-width-100">{strings.tagline}</p>
                                        <Link smooth to="/#process" className="btn btn-custom margin-t-30 waves-effect waves-light">{strings['learn-more-btn']} <i className="mdi mdi-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                {/* Process Component*/}
                <Process
                    strings={strings} 
                />

                <div className="col-lg-8 offset-lg-2 margin-t-20" id="your-rewards">
                    <h1 className="section-title text-center">{strings['your-rewards']}</h1>
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle text-muted text-center font-secondary padding-t-30">{strings['your-rewards-subtitle']}</p>
                </div>

                {/* Cache Preview Component
                // {cacheData.map((cache, i) => {
                //   return <div className="container" key={i}><CachePreview cacheId={i+1} cacheData={cacheData[i]}/></div>
                // })}
                */}

                <div className="treat-img-container">
                    {cacheData.map((cache, i) => {
                        return (
                            <div className="treat-img-child testimonial-decs" key={i}>
                                <img className="treat-img" src={"/images/cache/" + cache.imgTreat} />
                                <div className="treat-img-text">{cache.name}</div>
                            </div>
                        )
                    })}
                </div>


                <div className="col-lg-8 offset-lg-2 margin-t-50 align-content-center" id="instagram">
                    <h1 className="section-title text-center">{strings['instagram']}</h1>
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle text-muted text-center font-secondary padding-t-30">{strings['instagram-subtitle']}</p>
                    
                    <div className="insta-border margin-t-20">
                        <div className="insta-top-parent">
                          <div className="insta-avatar"><img src="/images/instagram/user.svg"/></div>
                          <div className="insta-handle">
                            <div><b>{strings['your-insta-handle']}</b></div>
                            <div>{strings['insta-tip']}</div>
                          </div>
                        </div>
                        
                        <Carousel autoPlay={false} interval={5000} showThumbs={false}>
                            <div>
                                <img src="images/instagram/8.jpg" />
                            </div>
                            <div>
                                <img src="images/instagram/3.jpg" />
                            </div>
                            <div>
                                <img src="images/instagram/5.jpg" />
                            </div>
                            <div>
                                <img src="images/instagram/7.jpg" />
                            </div>
                            <div>
                                <img src="images/instagram/1.jpg" />
                            </div>                
                        </Carousel>

                        <div className="insta-bottom">
                          <img className="insta-icons" src="/images/instagram/heart.svg"/>
                          <img className="insta-icons" src="/images/instagram/comment.svg"/>
                          <img className="insta-icons" src="/images/instagram/dm.svg"/>
                        </div>
                    </div>
                </div>

                {/* Pricing Component*/}
                <Pricing 
                    setModalBuyShow={setModalBuyShow}
                    strings={strings}
                />
               
                {/* Started Component*/}
                <Started 
                    setModalBuyShow={setModalBuyShow}
                    strings={strings}
                />

                {/* Testi Component*/}
                <Testi 
                    strings={strings}
                />

                {/* SocialMedia Component*/}
                <SocialMedia
                    strings={strings}
                />
                <ModalBuy
                  show={modalBuyShow}
                  locale={locale}
                  strings={strings}
                  onHide={() => setModalBuyShow(false)}
                  closeModal={() => setModalBuyShow(false)}
                />
            </div>
        </React.Fragment>
	)
}

export default Home;
import React from 'react';

const Testi = (props) => {
	return (
         <section className="section" id="testi">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="section-title text-center">{props.strings['what-they-said']}</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center font-secondary padding-t-30">{props.strings['what-they-said-subtitle']}</p>
                    </div>
                </div>
                <div className="row margin-t-50">
                    <div className="col-lg-4">
                        <div className="testimonial-box hover-effect margin-t-30">
                            <img src="images/testimonials/user-2.jpg" alt="" className="img-fluid d-block img-thumbnail rounded-circle" />
                            <div className="testimonial-decs">
                                <p className="text-muted text-center mb-0">“I live here and never knew about "the secret" donut shop -- so cool!” </p>
                            </div>
                            <h5 className="text-center text-uppercase padding-t-15">Gaby - <span className="text-muted text-capitalize">Mexico City</span></h5>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="testimonial-box hover-effect margin-t-30">
                            <img src="images/testimonials/user-1.jpg" alt="" className="img-fluid d-block img-thumbnail rounded-circle" />
                            <div className="testimonial-decs">
                                <p className="text-muted text-center mb-0">“A mis invitados de Airbnb les encanta. Es una recomendación fácil cuando alguien está buscando explorar Roma y Condesa.” </p>
                            </div>
                            <h5 className="text-center text-uppercase padding-t-15">Juan Carlos - <span className="text-muted text-capitalize">Mexico City</span></h5>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="testimonial-box hover-effect margin-t-30">
                            <img src="images/testimonials/user-3.jpg" alt="" className="img-fluid d-block img-thumbnail rounded-circle" />
                            <div className="testimonial-decs">
                                <p className="text-muted text-center mb-0">"Big fan of "The Sweet Trail". Bet you can't eat them all yourself!"</p>
                            </div>
                            <h5 className="text-center text-uppercase padding-t-15">Austin - <span className="text-muted text-capitalize">Michigan</span></h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	)
}
export default Testi;
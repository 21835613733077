import React from 'react';

const Started = (props) => {
  	return (
        <section className="section section-lg bg-get-start">
            <div className="bg-overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h1 className="get-started-title text-white">{props.strings['hunt-now-or-later']}</h1>
                        <div className="section-title-border margin-t-20 bg-white"></div>
                        <p className="section-subtitle font-secondary text-white text-center padding-t-30">{props.strings['hunt-now-or-later-subtitle']}</p>
                        <button onClick={() => props.setModalBuyShow(true)} className="btn btn-bg-white waves-effect margin-t-20 mb-4">{props.strings['get-started-btn']} <i className="mdi mdi-arrow-right"></i> </button>
                    </div>
                </div>
            </div>
            <div className="bg-pattern-effect">
                <img src="images/bg-pattern-light.png" alt="" />
            </div>
        </section>
    )
}
export default Started;
import React from 'react';
import { Link } from 'react-router-dom';

const Process = (props) => {

    return (
        <section className="section bg-light" id="process">
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="section-title text-center">{props.strings['how-it-works']}</h1>
                    <div className="section-title-border margin-t-20"></div>
                </div>
            </div>

            <div className="how-to-container">
                <div className="text-center margin-t-20">
                    <h4 className="padding-t-15">{props.strings['find-the-treasure']}</h4>
                    <p className="text-muted">{props.strings['find-the-treasure-cap']}</p>
                    <img className="img-400" src="images/howto/1.svg" />
                </div>

                <div className="text-center margin-how-to">
                    <h4 className="padding-t-15">{props.strings['unlock-your-treat']}</h4>
                    <p className="text-muted">{props.strings['unlock-your-treat-cap']}</p>
                    <img className="img-400" src="images/howto/2.svg" />
                </div>

                <div className="text-center margin-how-to">
                    <h4 className="padding-t-15">{props.strings['redeem-your-treat']}</h4>
                    <p className="text-muted">{props.strings['redeem-your-treat-cap']}</p>
                    <img className="img-400" src="images/howto/3.svg" />
                </div>

            </div>
        </div>
        </section>
    );
}
export default Process;
import React from 'react';

const Pricing = (props) => {
    
    const benefits = props.strings['benefit-list'].split(',')

    return (
        <section className="section pt-5" id="pricing">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h1 className="section-title text-center">{props.strings['pricing']}</h1>
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle font-secondary text-muted text-center padding-t-30">{props.strings['pricing-subtitle']}</p>
                </div>
            </div>
            <div className="row pricing-row">
                <div className="col-lg-12">
                    <div className="text-center pricing-box bg-white hover-effect price-active">
                        <h1>{props.strings['price']}</h1>
                        <h6 className="text-uppercase text-muted">{props.strings['price-detail-1']}</h6>
                        <h6 className="text-uppercase text-muted">{props.strings['price-detail-2']}</h6>
                        <h6 className="text-uppercase text-muted">{props.strings['price-detail-3']}</h6>
                        <div className="pricing-border"></div>
                        <div className="plan-features margin-t-30">
                            {benefits.map((value, index) => {
                                return <p key={index}><b className="text-custom">{value}</b></p>
                            })}
                        </div>
                        <button onClick={() => props.setModalBuyShow(true)} className="btn btn-custom waves-effect waves-light margin-t-30">{props.strings['lets-do-it-btn']}</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
export default Pricing;
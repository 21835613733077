import React, { useState } from 'react'
import { Modal, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';


const ModalQuestion = (props) => {

  const [modalQuestionShow, setModalQuestionShow] = useState(false)
  const [goBtnShow, setGoBtnShow] = useState(false)
  const [answerA, setAnswerA] = useState("")
  const [answerB, setAnswerB] = useState("")
  const [answerC, setAnswerC] = useState("")

  //const unlockUrl = "/cache?id=" + props.gameData.id + "&pc=" + props.gameData.pc + "&act=unlock&cid=" + props.cacheId
  //const cacheUrl = "/cache?id=" + props.gameData.id + "&pc=" + props.gameData.pc + "&cid=" + props.cacheId

  const handleAnswer = (btn) => {
    switch (btn) {
      case "A":
        setAnswerA("answers-wrong")
        break
      case "B":
        setAnswerB("answers-correct")
        setGoBtnShow(true)
        break
      case "C":
        setAnswerC("answers-wrong")
        break
    }  
  }

  return (
    <div>    
      {props.gameData[props.pid + "p-unlock"] ? (
          <div className="flex flex-horizontal-center"><Link className="btn btn-custom" to={"/trail?id=" + props.gameData['id'] + "&pc=" + props.gameData['pc']}>{props.strings['back-treat-map']}</Link></div>
          ) : (
          <div className="flex flex-horizontal-center"><button className="btn btn-custom" onClick={() => setModalQuestionShow(true)}><b><i className="pe-7s-key large-icon"></i></b></button></div>
      )}

      <Modal
        show={modalQuestionShow}
        onHide={() => setModalQuestionShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='text-center'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.strings['unlock-next']}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="question">{props.photo.question.q}</div>
            <button className={"btn answers " + answerA} onClick={()=>handleAnswer("A")}>{props.photo.question.a}</button>
            <button className={"btn answers " + answerB} onClick={()=>handleAnswer("B")} >{props.photo.question.b}</button>
            <button className={"btn answers " + answerC} onClick={()=>handleAnswer("C")}>{props.photo.question.c}</button>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {goBtnShow ? <div className="flex flex-horizontal-center"><Link to={"/trail?id=" + props.gameData['id'] + "&pc=" + props.gameData['pc'] + "&act=unlock&pid=" + props.pid} className="btn btn-custom">{props.strings['stop-unlocked-btn']}</Link></div> : null }
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ModalQuestion
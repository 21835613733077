import React from 'react';

const Navbar = (props) => {
  	return (
        <nav className={props.show ? "navibar navi-sticky" : "navibar navi-top"}>
            <div className= "navi-container">
                <button className="btn btn-custom" onClick={()=> props.setModalBuyShow(true)}>
                    {props.strings['hunt-now']}
                </button>
            </div>
        </nav>
  	)
}

export default Navbar
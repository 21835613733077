import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import * as registerServiceWorker from './registerServiceWorker'
import Home from './Home';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

const App = () => {

  return (
    <BrowserRouter>
      <div className="full-vert">
            <Switch>
              <Route path="/" exact component={Home} />
            </Switch>
      </div>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
registerServiceWorker.register();

import React, { useState } from 'react'
import { PayPalButton } from "react-paypal-button-v2"
import { Modal, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import fetchJsonp from 'fetch-jsonp'
import * as clipboard from "clipboard-polyfill/dist/clipboard-polyfill.promise"


const ModalBuy = (props) => {

  const [paymentWorkflow, setPaymentWorkflow] = useState({state: "START"})
  const [isLoading, setIsLoading] = useState(false)
  const [loadingMsg, setLoadingMsg] = useState(props.strings['processing-payment'])
  const [modalTitle, setModalTitle] = useState(props.strings['checkout'])
  const [copyEmailBtn, setCopyEmailBtn] = useState(props.strings['copy-email']) 

  const logOrder = async (email, orderId) => {
    var query = '?r=order&e=' + email + '&l=' + props.locale + '&o=' + orderId + '&p=400MXN'
    await fetchJsonp(process.env.REACT_APP_BACKEND_URL + query, {
        method: 'GET',
        jsonpCallbackFunction: 'callback',
      })
      .then((response) => response.json())
      .then((responseJson) => { 
          console.log(responseJson)
          const responseObj = JSON.parse(responseJson)
          if (responseObj.query) {
            setPaymentWorkflow({state: "SUCCESS", email: email, link: "https://play.eldulcecamino.com/trail?" + responseObj.query})
            setModalTitle(props.strings['payment-success'])
          } else {
            setPaymentWorkflow({state: "ERROR", error: "Game generation failed."})
          }
          })
      .catch((error) => {
        setPaymentWorkflow({state: "ERROR", error: error})})

  }

  const copyEmail = (copyContent) => {
    clipboard.writeText(copyContent);
    setCopyEmailBtn(props.strings['copied'])
  }


  const paymentWorkflowContent = (paymentWorkflow) => {
    //AdDr26pdiRlMXbMQ3nNk7mPbxRniwf0gUME6fDGWTCPjhy4ocjdIa0L326zHZAuSidqPPbGUL9xr1YsE
    const options = {
      clientId: "sb",
      currency: "MXN"
    }

    //Needed for Paypal
    if (props.locale === "ES")
      options.locale = "es_MX"
    else
      options.locale = "en_MX"


    switch(paymentWorkflow.state) {
      case 'START':
        return(
          <React.Fragment>

              <div className="height-75vh" hidden={isLoading}>
                <div className="conditions">
                  {props.strings['conditions-1']}
                  <ul>
                    <li>{props.strings['conditions-2']}</li>
                    <li>{props.strings['conditions-3']}</li>
                    <li>{props.strings['conditions-4']}</li>
                    <li>{props.strings['conditions-5']}</li>
                    <li>{props.strings['conditions-6']}</li>
                    <li>{props.strings['conditions-7']}</li>
                    <li>{props.strings['conditions-8']}</li>
                    <li>{props.strings['conditions-9']}</li>
                  </ul>
                  {props.strings['agreement']}
                  </div>

              

                <PayPalButton
                  onClick={()=>{
                    setIsLoading(true)
                  }}
                  amount="400"
                  currency="MXN"
                  options={options}
                  shippingPreference="NO_SHIPPING"
                  onSuccess={(details, data) => {
                    setLoadingMsg(props.strings['drawing-map'])
                    logOrder(details.payer.email_address, data.orderID)
                  }}
                  onCancel={() => {
                    setIsLoading(false)
                  }}
                />
              </div>

              <div hidden={!isLoading}>
                <div className="height-50vh">
                  <div className="flex flex-horizontal-center"><img className="horizontal-center-item" src="images/loader.gif" /></div>
                  <div className="flex flex-horizontal-center">{ loadingMsg }</div>
                </div>
              </div>
            
          </React.Fragment>
        )
      case 'SUCCESS':
        return(
          <React.Fragment>
            <div className="flex flex-horizontal-center"><img className="horizontal-center-item" src="images/loader.gif" /></div>
            <div className="flex flex-horizontal-center">{props.strings['emailed-link']}</div>
            <div ><b>{paymentWorkflow.email}</b></div>
          </React.Fragment>
        )
      case 'ERROR':
        return(
          <React.Fragment>
            <div className="flex flex-horizontal-center"><img className="horizontal-center-item" height="100px" src="images/error.gif" /></div>
            <div className="flex flex-horizontal-center margin-t-20">{props.strings['payment-fail']}</div>
          </React.Fragment>
        )      
    }
  }

  const paymentWorkflowButton = (paymentWorkflow) => {

    switch(paymentWorkflow.state) {
      case 'SUCCESS': 
        return (<button onClick={()=> { window.location.href = paymentWorkflow.link }} className="btn btn-custom">{props.strings['treasure-map-btn']}</button>)

      case 'ERROR':
        return (<button onClick={()=> copyEmail(props.strings['support-email'])} className="btn btn-custom">{copyEmailBtn}</button>)
    }

  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='text-center'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {paymentWorkflowContent(paymentWorkflow)}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {paymentWorkflowButton(paymentWorkflow)}
      </Modal.Footer>
    </Modal>
  )
}

export default ModalBuy